import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link, Outlet, Navigate } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ErrorBoundary from './components/ErrorBoundary';
import Header from './components/Header';
import Footer from './components/Footer';
import Navigations from './components/Navigations';
import CommonModal from './components/CommonModal';

const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Vanilla = React.lazy(() => import('./pages/vanilla/Vanilla'));
const Miraeasset = React.lazy(() => import('./pages/miraeasset/Miraeasset'));
const Hanwhageneral = React.lazy(() => import('./pages/hanwhageneral/Main'));
const Rpot = React.lazy(() => import('./pages/rpot/Main'));

function App() {
  const stageNameMap = {
    production: '운영',
    staging: '검증',
    development: '개발',
  };

  const version = '신화 업무 포탈 v202409100943';
  const title = '신화 업무 포탈-' + (stageNameMap[process.env.REACT_APP_ENV] || '오류');

  //전체 웹앱 초기화 함수
  useEffect(() => {
    console.log(`app이 최초 1회 초기화 됨, 실행 스테이지명 : '${process.env.REACT_APP_ENV}'`);
    document.title = version; //html 타이틀 수정
  }, []);

  return (
    <>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <ErrorBoundary>
          <RecoilRoot>
            <BrowserRouter>
              <Suspense fallback={<CommonModal message="loading"></CommonModal>}>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <>
                        <Header title={title} />
                        <div id="layoutSidenav">
                          <Navigations />
                          <div id="layoutSidenav_content">
                            <Outlet />
                            <Footer />
                          </div>
                        </div>
                        <ToastContainer />
                        {/* <CommonModal message="서버에 예기치 못한 오류가 발생 하였습니다. 현재 화면을 캡쳐 혹은 촬영하여 개발자에게 보내주세요!"></CommonModal> */}
                      </>
                    }>
                    <Route index element={<Navigate to="/Dashboard" replace={true} />} />
                    <Route path="Dashboard/*" element={<Dashboard />} />
                    <Route path="Vanilla/*" element={<Vanilla />} />
                    <Route path="Rpot/*" element={<Rpot />} />
                    <Route path="Miraeasset/*" element={<Miraeasset />} />
                    <Route path="Hanwhageneral/*" element={<Hanwhageneral />} />
                    <Route path="Settings/*" element={<div>Settings</div>} />
                    <Route path="LoginHistory/*" element={<div>LoginHistory</div>} />
                    <Route path="Logout/*" element={<div>Logout</div>} />
                    <Route
                      path="mirae"
                      element={
                        <div>
                          미래입니다. <Link to="/Dashboard">to main</Link>
                        </div>
                      }
                    />
                    <Route path="*" element={<Navigate to="/Dashboard" replace={true} />} />
                  </Route>
                </Routes>
              </Suspense>
            </BrowserRouter>
          </RecoilRoot>
        </ErrorBoundary>
      </MsalAuthenticationTemplate>
    </>
  );
}

export default App;
